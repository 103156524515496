import { arrayOf, bool, number, oneOf, shape, string } from "prop-types"

import { FocusableItemTypes, Status } from "src/features/UniversalAi/constants"

export const FocusableItem = shape({
  id: string.isRequired,
  title: string.isRequired,
  type: oneOf(FocusableItemTypes)
})

export const Source = shape({
  author: string,
  breadcrumbs: arrayOf(string),
  createdAt: string,
  documentType: string,
  except: string,
  link: string,
  sourceType: string.isRequired,
  sourceId: number.isRequired,
  sourceDeleted: bool,
  page: number,
  title: string.isRequired
})

export const SearchResultItemBreadcrumb = shape({
  name: string.isRequired,
  path: string
})

export const SearchResultItemType = {
  AgendaItemDocument: "agenda_item_document",
  AgendaItemDescription: "agenda_item_description",
  Message: "message",
  Attachment: "attachment",
  Document: "document",
  ContentCloudFile: "content_cloud_file",
  Transcript: "transcript",
  AssignedTranscriptAgendaItem: "assigned_transcript_agenda_item",
  IframeContent: "iframe_content"
}

export const SearchResultItemTypes = Object.values(SearchResultItemType)

export const SearchResultItem = shape({
  id: string.isRequired,
  type: oneOf(SearchResultItemTypes).isRequired,
  text: string,
  title: string,
  breadcrumbs: arrayOf(SearchResultItemBreadcrumb),
  path: string,
  sourceDeleted: bool
})

export const ThreadItem = shape({
  id: string.isRequired,
  focusable: FocusableItem.isRequired,
  query: string.isRequired,
  answer: string,
  sources: arrayOf(Source),
  searchResults: arrayOf(SearchResultItem),
  status: oneOf(Object.values(Status)),
  externalContentAnswer: shape({
    externalContentInstance: shape({ name: string.isRequired }),
    status: oneOf(Object.values(Status)),
    answerText: string
  })
})
