import useAiAskQuestion from "src/hooks/ai/useAskQuestion"
import useFeatureFlag from "src/hooks/useFeatureFlag"
import { getCurrentUserId } from "src/helpers/user"

import { STREAMING_START_DELAY } from "../constants"

import useSearch from "./useSearch"
import useDefineQueryType from "./useDefineQueryType"
import { QueryTypes } from "./constants"

const useSubmitQuery = ({
  focusedItem,
  onSubmit,
  onQueryTypeChange,
  onChange,
  onComplete,
  onSourcesComplete,
  onSearchResultsComplete,
  onExternalContentAnswerLoad,
  onExternalContentAnswerComplete
}) => {
  const defineQueryType = useDefineQueryType()
  const userId = getCurrentUserId()
  const { flagValue: summaryByQueryEnabled } = useFeatureFlag(
    "ai-intelligent-summarising",
    {
      context: { userId }
    }
  )

  const search = useSearch({
    focusedItem,
    onComplete: onSearchResultsComplete
  })

  const { askQuestion, stopStreaming } = useAiAskQuestion({
    objectId: focusedItem?.id,
    objectType: focusedItem?.type,
    onChange,
    onComplete,
    onSourcesComplete,
    onExternalContentAnswerLoad,
    onExternalContentAnswerComplete,
    startDelay: STREAMING_START_DELAY
  })

  const submitQuery = async (query) => {
    onSubmit()

    const type = await defineQueryType(query)
    onQueryTypeChange(type)

    if (type === QueryTypes.Keyword) {
      search(query)
    } else if (type === QueryTypes.Query) {
      askQuestion(query, {})
    } else if (type === QueryTypes.SummariseByQuery) {
      askQuestion(query, { summarise: !!summaryByQueryEnabled })
    }
  }

  return {
    submitQuery,
    stopStreaming
  }
}

export default useSubmitQuery
