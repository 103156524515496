import React from "react"
import { bool, func } from "prop-types"
import { Col } from "react-bootstrap"

import PublicationBadge from "src/components/MeetingPacks/PublicationBadge"
import TextWithTooltip from "src/styles/components/TextWithTooltip"
import { formatDateTime } from "src/helpers/datetime"
import { PUBLICATION_STATUSES } from "src/resources/meetingPacks/constants"

import { meetingPackType } from "../constants"

import {
  MeetingContainer,
  SelectionIcon,
  MeetingCard,
  CardRow,
  CardDivider,
  MeetingInformation,
  MeetingName,
  DesktopMeetingTag,
  MobileMeetingTag,
  MeetingStartDate
} from "./styles"

const MeetingPack = ({ meetingPack, selected, onClick }) => {
  const iconType = selected ? "complete" : "no-status"

  const showPublicationStatus =
    meetingPack.publicationBadge !== PUBLICATION_STATUSES.published

  const startDate = formatDateTime({
    datetime: meetingPack.startDate,
    timeZone: meetingPack.timeZone
  })

  return (
    <MeetingContainer>
      <SelectionIcon type={iconType} onClick={onClick} />
      <MeetingCard body onClick={onClick}>
        <CardRow>
          <Col xs={12} md={7}>
            <MeetingInformation>
              <MeetingName data-testid="meeting-pack-name">
                <TextWithTooltip text={meetingPack.name} maxLength={100} />
                {!!meetingPack.tags.length && (
                  <DesktopMeetingTag color={meetingPack.tags[0].colorId}>
                    {meetingPack.tags[0].name}
                  </DesktopMeetingTag>
                )}
              </MeetingName>
              {showPublicationStatus && (
                <PublicationBadge
                  publicationStatus={meetingPack.publicationBadge}
                  meetingPackId={meetingPack.id}
                />
              )}
            </MeetingInformation>
            {!!meetingPack.tags.length && (
              <MobileMeetingTag color={meetingPack.tags[0].colorId}>
                {meetingPack.tags[0].name}
              </MobileMeetingTag>
            )}
          </Col>
          <CardDivider />
          <Col xs={12} md={5}>
            <MeetingStartDate>
              <TextWithTooltip text={startDate} maxLength={0} />
            </MeetingStartDate>
          </Col>
        </CardRow>
      </MeetingCard>
    </MeetingContainer>
  )
}

MeetingPack.propTypes = {
  meetingPack: meetingPackType,
  selected: bool.isRequired,
  onClick: func.isRequired
}

export default MeetingPack
