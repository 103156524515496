import { styled } from "@linaria/react"

import UnstyledButton from "src/styles/components/Button/Unstyled"
import { BREAKPOINTS, SPACES } from "src/styles/sizes"

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACES.xSmall};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: none;
  }
`

export const MobileActionsContainer = styled.div`
  margin-bottom: ${SPACES.xSmall};

  @media (min-width: calc(${BREAKPOINTS.small})) {
    display: none;
  }
`

export const SelectAllButton = styled(UnstyledButton)`
  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    margin-top: ${SPACES.small};
  }
`
