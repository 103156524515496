import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import BaseIcon from "src/styles/components/Icon"

export const AiIcon = styled(BaseIcon)``
export const DropdownIcon = styled(BaseIcon)``

export const MagicWandIcon = styled.img`
  margin-right: 5px;
`

export const Toggle = styled(UnstyledButton)`
  border-radius: 4px;
  padding: 6px 8px;
  margin-left: 3px;

  ${DropdownIcon} {
    font-weight: bold;
    position: relative;
    top: 1px;
    color: #4f4f58;
    font-size: 14px;
    transition: transform 0.3s ease-out;

    &.opened {
      transform: rotate(-180deg);
      top: 0;
      left: 1px;
    }
  }
`

export const DropdownMenu = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  background-image: radial-gradient(
    97.76% 141.96% at 0% 6.25%,
    #9647d7 14.58%,
    #cba3eb 47.92%,
    #1a7ef3 75%,
    #00cc7e 100%
  );
  background-origin: border-box;
  background-clip: border-box;
  box-shadow: 0px 4px 6px -1px #0000001a;
  width: max-content;
  z-index: 9999;
`

export const DropdownMenuContent = styled.div`
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`

export const DropdownMenuItem = styled(UnstyledButton)`
  padding: 12px 16px;
  border-bottom: 1px solid #1919331a;
  font-weight: 400;
  font-size: 16px;
  color: #212529;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #e9ecef;
  }

  ${AiIcon} {
    color: #2c2e36;
    margin-right: 16px;
    font-size: 18px;
  }
`

export const SummariseDropdownItem = styled(DropdownMenuItem)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

export const QueryDropdownItem = styled(DropdownMenuItem)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`
