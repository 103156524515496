import { bool, func } from "prop-types"
import React from "react"

import { CopyIcon, CopyResponseButton, CopyResponseContainer } from "./styles"

const CopyResponse = ({ copied, onCopy }) => {
  return (
    <CopyResponseContainer>
      <CopyResponseButton onClick={onCopy}>
        <CopyIcon type="copy" />
        {copied ? "Copied!" : "Copy response"}
      </CopyResponseButton>
    </CopyResponseContainer>
  )
}

CopyResponse.propTypes = {
  copied: bool,
  onCopy: func
}

export default CopyResponse
