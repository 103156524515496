import React from "react"
import { number, shape, string } from "prop-types"
import { Routes, Route } from "react-router-dom"

import { withErrorBoundary } from "src/app/ErrorBoundary"

import * as routes from "./routes"
import AttendeesTable from "./Users/AttendeesTable"
import BulkAddUserToMeetingPacks from "./Users/BulkAddUserToMeetingPacks"
import Teams from "./Teams"
import AirtableApps from "./AirtableApps"
import EditApp from "./AirtableApps/EditApp"
import NewApp from "./AirtableApps/NewApp"
import Overview from "./AirtableApps/Overview"
import EditPage from "./AirtableApps/EditPage"

const ManageAccount = ({ account }) => {
  return (
    <Routes>
      <Route
        path={routes.manageAttendees(account.id)}
        element={<AttendeesTable account={account} />}
      />
      <Route
        path={routes.bulkAddUserToMeetingPacks(account.id, ":userId")}
        element={<BulkAddUserToMeetingPacks accountId={account.id} />}
      />
      <Route
        path={routes.manageApps(account.id)}
        element={<AirtableApps accountId={account.id} />}
      />
      <Route
        path={routes.manageTeams(account.id)}
        element={<Teams accountId={account.id} />}
      />
      <Route
        path={routes.newApp(account.id)}
        element={<NewApp accountId={account.id} />}
      />
      <Route
        path={routes.showApp(account.id, ":id")}
        element={<Overview accountId={account.id} />}
      />
      <Route
        path={routes.editApp(account.id, ":id")}
        element={<EditApp accountId={account.id} />}
      />
      <Route
        path={routes.editPage(account.id, ":appId", ":id")}
        element={<EditPage accountId={account.id} />}
      />
    </Routes>
  )
}

ManageAccount.propTypes = {
  account: shape({ id: number.isRequired, name: string.isRequired })
}

export default withErrorBoundary(ManageAccount)
