import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import Icon from "src/styles/components/Icon"

export const Container = styled.div`
  margin-top: 30px;
  padding: 15px 16px 20px 16px;
  box-shadow: 0px 2px 5px 0px #73839e26;
  border-radius: 8px;
  border: 1px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(221, 11, 49, 0) 13%,
    #dd0b31 50.5%,
    rgba(221, 11, 49, 0) 88%
  );
  position: relative;
`

export const Title = styled.div`
  color: #dd0b31;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  font-family: "Space Grotesk";
`

export const ColorfulBorder = styled.div`
  position: absolute;
  /* transform: rotate(90deg); */
  top: 0;
  left: -1px;
  height: 100%;
  width: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      rgba(221, 11, 49, 0) 8%,
      #dd0b31 50.5%,
      rgba(221, 11, 49, 0) 93%
    )
    1;
  border-image-width: 2px;
`

export const CloseButton = styled(UnstyledButton)`
  margin-top: 16px;
  text-align: center;
  width: 100%;
  display: inline-block;
`

export const CloseIcon = styled(Icon)`
  display: inline-block;
  font-size: 20px;
`
