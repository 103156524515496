import { useCallback, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { captureException } from "@sentry/browser"

import { CHANNELS } from "src/constants/ably"
import { FAIL_MESSAGE } from "src/features/UniversalAi/constants"
import { summarizeViewed } from "src/constants/storage"
import useChannelName from "src/features/UniversalAi/Sidebar/useChannelName"

import useRealtimeUpdates from "../useRealtimeUpdates"

import generateAiTextMutation from "./generateAiTextMutation.gql"
import useStreaming from "./useStreaming"

const useGenerateText = ({
  skip,
  objectId,
  objectType,
  onSubmit,
  onChange,
  onComplete,
  startDelay = 0
}) => {
  const channelName = useChannelName(
    `summarise-${objectType}`,
    CHANNELS.aiAnswer[objectType]
  )

  const [generateText] = useMutation(generateAiTextMutation)

  const {
    setContent,
    finished,
    setFinished,
    reset,
    interrupt: stopStreaming
  } = useStreaming({
    streamId: "summarise",
    onChange,
    onComplete,
    startDelay,
    channelName
  })

  useEffect(() => {
    if (finished) localStorage.setItem(summarizeViewed(objectType, objectId), "t")
  }, [finished])

  const shouldExecuteGeneration = !skip && objectId && objectType && channelName

  useEffect(() => {
    if (!shouldExecuteGeneration) return

    reset()
    onSubmit()

    generateText({
      variables: { objectId, objectType, channelName }
    })
      .then((response) => {
        const { data } = response

        if (!data?.generateAiText?.success) {
          console.error("generateAiText mutation error", response)
          setContent(FAIL_MESSAGE)
        }
      })
      .catch((error) => {
        console.error("useGenerateText error", error)
        captureException(error)
        setContent(FAIL_MESSAGE)
      })
  }, [shouldExecuteGeneration])

  const handleRealtimeUpdates = useCallback(
    (data) => {
      const currentObjectMessage =
        data.objectId?.toString() === objectId?.toString() &&
        data.objectType === objectType

      if (!currentObjectMessage) return

      if (
        data.streamAnswer &&
        localStorage.getItem(summarizeViewed(objectType, objectId))
      ) {
        setContent(data.answer, { stream: false })
        setFinished(true)
      } else if (data.answer) {
        setContent(data.answer)
        if (data.finished) setFinished(true)
      } else if (!data.success) {
        setContent(data.error || FAIL_MESSAGE)
      }
    },
    [objectId, objectType]
  )

  useRealtimeUpdates(channelName, handleRealtimeUpdates)

  return { stopStreaming }
}

export default useGenerateText
