import React from "react"
import { number } from "prop-types"
import { useParams, useLocation } from "react-router-dom"

import Title from "src/features/ManageAccount/Shared/Title"
import { manageAttendees as manageAttendeesRoute } from "src/features/ManageAccount/routes"
import ContentLoader from "src/styles/components/ContentLoader"
import EmptyContent from "src/styles/components/EmptyContent"

import useAccountMembershipsQuery from "../useAccountMembershipsQuery"

import List from "./List"
import { Container } from "./styles"

const BulkAddUserToMeetingPacks = ({ accountId }) => {
  let { user } = useLocation().state || {}
  const { userId } = useParams()

  const { accountMemberships, loading } = useAccountMembershipsQuery({
    accountId,
    userId,
    skip: !!user
  })

  if (loading) return <ContentLoader />

  user ||= accountMemberships[0]?.user

  if (!user) return <EmptyContent>User not found.</EmptyContent>

  return (
    <div className="fluid-container">
      <Container>
        <Title
          text={`Bulk add ${user.fullName} to past meeting packs`}
          backButtonRoute={manageAttendeesRoute(accountId)}
          backButtonText="Back to Manage Org"
        />

        <List accountId={accountId} user={user} />
      </Container>
    </div>
  )
}

BulkAddUserToMeetingPacks.propTypes = {
  accountId: number.isRequired
}

export default BulkAddUserToMeetingPacks
