import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store"

const useExpandedExternalContentAnswerSources = (threadItem) => {
  const { expandedExternalContentAnswerSources } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const expanded =
    // sources should be opened by default for current thread item
    // (when user hasn't clicked on Sources collapse) so we compare
    // with undefined to check whether user has interacted
    // with Sources collapse
    expandedExternalContentAnswerSources[threadItem.id] === undefined
      ? !!threadItem.current
      : !!expandedExternalContentAnswerSources[threadItem.id]

  const expand = () => {
    dispatch(actions.expandExternalContentAnswerSources(threadItem.id))
  }

  const collapse = () => {
    dispatch(actions.collapseExternalContentAnswerSources(threadItem.id))
  }

  const toggle = () => {
    if (expanded) {
      collapse()
    } else {
      expand()
    }
  }

  return { expanded, expand, collapse, toggle }
}

export default useExpandedExternalContentAnswerSources
