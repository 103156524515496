import React, { forwardRef } from "react"
import { func, shape } from "prop-types"

import { Source } from "src/features/UniversalAi/Sidebar/propTypes"
import { buildTitleWithDeletedLabel } from "src/features/UniversalAi/Sidebar/helpers"

import {
  Container,
  Title,
  Icon,
  SourcePages,
  Excerpt,
  ExcerptDivider,
  ExcerptText,
  Breadcrumbs
} from "./styles"
import CreationInfo from "./CreationInfo"

const SourcePreview = forwardRef(({ source, style, onNavigate }, ref) => {
  return (
    <Container ref={ref} style={style}>
      <Title onClick={onNavigate}>
        {source.documentType && (
          <Icon extension={source.documentType} showExtension={false} />
        )}

        {buildTitleWithDeletedLabel(source.title, source.sourceDeleted)}
      </Title>

      {source.page && <SourcePages>Page {source.page}</SourcePages>}

      {source.excerpt && (
        <Excerpt>
          <ExcerptText>{source.excerpt}</ExcerptText>
        </Excerpt>
      )}

      {(source.breadcrumbs || source.createdAt) && <ExcerptDivider />}

      {source.breadcrumbs && (
        <Breadcrumbs>
          {source.breadcrumbs.map((breadcrumb, index) => {
            return (
              <span key={breadcrumb}>
                {breadcrumb}
                {index !== source.breadcrumbs.length - 1 && " > "}
              </span>
            )
          })}
        </Breadcrumbs>
      )}

      <CreationInfo source={source} />
    </Container>
  )
})

SourcePreview.displayName = "SourcePreview"

SourcePreview.propTypes = {
  source: Source.isRequired,
  onNavigate: func,
  style: shape({})
}

export default SourcePreview
