import React, { useRef } from "react"
import { useFloating, autoUpdate, flip, shift, useMergeRefs } from "@floating-ui/react"
import { arrayOf, bool, func, oneOf, string } from "prop-types"

import { Source } from "src/features/UniversalAi/Sidebar/propTypes"
import { SourceClickTarget } from "src/features/UniversalAi/constants"

import AnswerContainer from "../AnswerContainer"
import CopyResponse from "../CopyResponse"
import useCopy from "../useCopy"
import Sources, { LinkTitleMode, LinkTitleModes } from "../Sources"

import SourcePreview from "./SourcePreview"
import useSourceInteractions from "./useSourceInteractions"

const Answer = ({
  answerText,
  sources,
  sourcesExpanded,
  toggleSources,
  onSourceNavigate,
  inProgress = false,
  linkTitleMode = LinkTitleMode.Title
}) => {
  const answerRef = useRef()
  const { copied, copy } = useCopy({ parent: answerRef.current, content: answerText })

  const sourcePreviewRef = useRef()
  const { source: activeSource, htmlElement } = useSourceInteractions({
    sources,
    sourcePreviewRef,
    onSourceNavigate,
    containerRef: answerRef,
    skip: inProgress
  })

  const { refs, floatingStyles } = useFloating({
    elements: { reference: htmlElement },
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift()]
  })

  const allSourcePreviewRefs = useMergeRefs([sourcePreviewRef, refs.setFloating])

  const showSourcePreview = !!activeSource
  const showSources = !inProgress && sources?.length > 0

  return (
    <>
      <AnswerContainer
        answerText={answerText}
        sourcesVisible={!inProgress}
        ref={answerRef}
      />

      {showSources && (
        <Sources
          sources={sources}
          opened={sourcesExpanded}
          toggle={toggleSources}
          linkTitleMode={linkTitleMode}
          onSourceNavigate={onSourceNavigate}
        />
      )}

      {!!answerText && !inProgress && <CopyResponse onCopy={copy} copied={copied} />}

      {showSourcePreview && (
        <SourcePreview
          ref={allSourcePreviewRefs}
          style={floatingStyles}
          source={activeSource}
          onNavigate={() =>
            onSourceNavigate(activeSource, SourceClickTarget.PreviewTitle)
          }
        />
      )}
    </>
  )
}

Answer.propTypes = {
  answerText: string.isRequired,
  sources: arrayOf(Source).isRequired,
  sourcesExpanded: bool.isRequired,
  toggleSources: func.isRequired,
  linkTitleMode: oneOf(LinkTitleModes),
  onSourceNavigate: func,
  inProgress: bool
}

export default Answer
