import React from "react"
import { Dropdown } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { number } from "prop-types"
import { useQuery } from "@apollo/client"

import * as routes from "src/features/ManageAccount/routes"
import Title from "src/features/ManageAccount/Shared/Title"
import useLayoutHeader from "src/hooks/useLayoutHeader"

import instancePagesQuery from "./instancePages.gql"

const Overview = ({ accountId }) => {
  const { id } = useParams()
  const { data } = useQuery(instancePagesQuery, {
    variables: { id }
  })
  useLayoutHeader({ useDefault: false, title: data?.appAirtableInstance?.name })

  const pages = data?.appAirtableInstance?.pages

  return (
    <>
      <Title
        text="Application Overview"
        backButtonRoute={routes.manageApps(accountId)}
        backButtonText="Back to Apps"
        RightComponent={
          <div className="subheader-actions">
            <Dropdown className="dropdown-menu-right">
              <Dropdown.Toggle className="subheader-control dropdown-toggle button is-dim">
                <span className="icon-gear" />
                <span className="control-text">App Options</span>
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                <Dropdown.Item href={routes.editApp(accountId, id)}>
                  Edit Application
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      />

      {pages && (
        <div className="container mt-3">
          {pages.map((page) => (
            <div className="card mb-3" key={page.id}>
              <Link className="text-dark" to={routes.editPage(accountId, id, page.id)}>
                <div className="card-title">
                  <h5>{page.name}</h5>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

Overview.propTypes = {
  accountId: number.isRequired
}

export default Overview
