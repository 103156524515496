import { bool, func, oneOf, shape, string } from "prop-types"
import React, { useState } from "react"

import { Status } from "src/features/UniversalAi/constants"

import Answer from "../Answer"

import LoadingState from "./LoadingState"
import { CloseButton, CloseIcon, ColorfulBorder, Container, Title } from "./styles"
import ShortAnswer from "./ShortAnswer"
import SourcePreview from "./SourcePreview"

const ExternalContentAnswer = ({
  externalContentAnswer,
  answerExpanded,
  onAnswerExpand,
  onAnswerCollapse,
  sourcesExpanded,
  onSourcesToggle,
  forceLoadingState = false
}) => {
  const [activeSource, setActiveSource] = useState(null)

  const {
    answerText,
    externalContentInstance,
    sources = [],
    status = Status.Finished
  } = externalContentAnswer

  const answerLoading = status === Status.Loading
  const loading = forceLoadingState || answerLoading
  const finished = status === Status.Finished
  const hasAnswer = !loading && answerText

  const handleSourceNavigate = (source) => {
    setActiveSource(source)
  }

  const closeSourcePreview = () => setActiveSource(null)

  if (!answerLoading && !finished) return null

  return (
    <Container>
      <ColorfulBorder />
      <Title>More from {externalContentInstance.name}</Title>

      {loading && <LoadingState externalContentInstance={externalContentInstance} />}

      {hasAnswer && !answerExpanded && (
        <ShortAnswer answerText={answerText} onClick={onAnswerExpand} />
      )}

      {hasAnswer && answerExpanded && (
        <>
          <Answer
            answerText={answerText}
            sources={sources}
            sourcesExpanded={sourcesExpanded}
            toggleSources={onSourcesToggle}
            onSourceNavigate={handleSourceNavigate}
          />
          <CloseButton onClick={onAnswerCollapse}>
            <CloseIcon type="caret-up-slim" />
          </CloseButton>
        </>
      )}

      {activeSource && (
        <SourcePreview
          onClose={closeSourcePreview}
          title={activeSource.title}
          sourceId={activeSource.sourceId}
          page={activeSource.page}
          highlights={activeSource.highlights}
          externalLink={activeSource.externalLink}
          externalContentInstanceName={externalContentInstance.name}
        />
      )}
    </Container>
  )
}

ExternalContentAnswer.propTypes = {
  externalContentAnswer: shape({
    status: oneOf(Object.values(Status)),
    answerText: string,
    externalContentInstance: shape({ name: string.isRequired })
  }).isRequired,
  answerExpanded: bool.isRequired,
  onAnswerExpand: func.isRequired,
  onAnswerCollapse: func.isRequired,
  sourcesExpanded: bool.isRequired,
  onSourcesToggle: func.isRequired,
  forceLoadingState: bool
}

export default ExternalContentAnswer
