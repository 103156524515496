import { styled } from "@linaria/react"
import { Card, Row } from "react-bootstrap"

import Icon from "src/styles/components/Icon"
import Tag from "src/styles/components/Tag"
import { BREAKPOINTS, SPACES, FONT_SIZES } from "src/styles/sizes"
import { TEXT_COLORS } from "src/styles/colors"

export const SelectionIcon = styled(Icon)`
  cursor: pointer;
`

export const MeetingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.small};
`

export const MeetingCard = styled(Card)`
  padding: 0;
  position: relative;
  margin: ${SPACES.xSmall} 0;
  color: ${TEXT_COLORS.heading};
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`

export const CardRow = styled(Row)`
  justify-content: space-between;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    flex-direction: column;
  }
`

export const CardDivider = styled.hr`
  display: none;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: flex;
    margin: ${SPACES.small};
  }
`

export const MeetingInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MeetingName = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`

export const DesktopMeetingTag = styled(Tag)`
  margin: 0 ${SPACES.xSmall};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: none;
  }
`

export const MobileMeetingTag = styled(Tag)`
  margin-top: ${SPACES.xSmall};

  @media (min-width: ${BREAKPOINTS.small}) {
    display: none;
  }
`

export const MeetingStartDate = styled.div`
  font-size: ${FONT_SIZES.small};
  text-align: right;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    text-align: left;
  }
`
