import { styled } from "@linaria/react"

import { SPACES, BREAKPOINTS, FONT_SIZES } from "src/styles/sizes"

export const ConfirmationPanelContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: ${SPACES.small} 0;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    padding-bottom: ${SPACES.xLarge};
  }
`

export const SelectedText = styled.div`
  font-size: ${FONT_SIZES.semiLarge};
`

export const MembershipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: ${SPACES.xSmall};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: block;
    padding-top: ${SPACES.small};
  }
`

export const RoleSelectField = styled.select`
  &.form-field {
    padding: 0.5rem 0.8rem;
    width: 7rem;

    @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
      width: 100%;
      margin: ${SPACES.xSmall} 0;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    justify-content: flex-start;
    padding-top: ${SPACES.small};
  }
`
