import { useDispatch } from "react-redux"

import { actions } from "./store"

const useUniversalAi = () => {
  const dispatch = useDispatch()

  const summariseAsTextInSidebar = ({ id, type }) =>
    dispatch(actions.summariseAsTextInSidebar({ id, type }))

  const queryInSidebar = ({ id, type }) => dispatch(actions.queryInSidebar({ id, type }))

  const generateExtensiveMinutesInSidebar = ({ id, type }) =>
    dispatch(actions.generateExtensiveMinutesInSidebar({ id, type }))

  const generateShortMinutesInSidebar = ({ id, type }) =>
    dispatch(actions.generateShortMinutesInSidebar({ id, type }))

  const setFocusedItem = ({ id, type, title }) =>
    dispatch(actions.setFocusedItem({ id, type, title }))

  const close = () => dispatch(actions.close())

  return {
    summariseAsTextInSidebar,
    queryInSidebar,
    generateExtensiveMinutesInSidebar,
    generateShortMinutesInSidebar,
    setFocusedItem,
    close
  }
}

export default useUniversalAi
