import React from "react"
import { oneOf, string } from "prop-types"

import { QueryTypes } from "src/features/UniversalAi/Sidebar/constants"
import { Commands } from "src/features/UniversalAi/constants"

import Quote from "./Quote"

const Query = ({ query, queryType, focusableTitle }) => {
  const isKeywordType = queryType === QueryTypes.Keyword

  const isSummariseCommand = query === Commands.SummariseAsText

  const isRegularQuery = !isKeywordType && !isSummariseCommand

  return (
    <>
      {isKeywordType && (
        <span>
          Search for <Quote>{query}</Quote>
        </span>
      )}

      {isSummariseCommand && `Summarise ${focusableTitle}`}

      {isRegularQuery && query}
    </>
  )
}

Query.propTypes = {
  query: string.isRequired,
  queryType: oneOf(Object.values(QueryTypes)),
  focusableTitle: string.isRequired
}

export default Query
