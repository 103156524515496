import React from "react"
import { string, func } from "prop-types"

import { ROLES } from "../constants"

import { RoleSelectField } from "./styles"

const RoleSelect = ({ role, setRole }) => {
  return (
    <RoleSelectField
      className="form-field"
      value={role}
      onChange={(e) => setRole(e.target.value)}
      data-testid="meeting-pack-role-select"
    >
      {Object.entries(ROLES).map(([key, name]) => (
        <option key={key} value={key}>
          {name}
        </option>
      ))}
    </RoleSelectField>
  )
}

RoleSelect.propTypes = {
  role: string.isRequired,
  setRole: func.isRequired
}

export default RoleSelect
