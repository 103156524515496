import React from "react"

import { splitFilename } from "src/helpers/string"
import {
  SearchResultItem,
  SearchResultItemType
} from "src/features/UniversalAi/Sidebar/propTypes"
import { buildTitleWithDeletedLabel } from "src/features/UniversalAi/Sidebar/helpers"
import useUniversalAi from "src/features/UniversalAi/useUniversalAi"
import useMobileScreen from "src/hooks/useMobileScreen"

import { Link, Icon } from "./styles"

const DOCUMENT_RESULT_TYPES = [
  SearchResultItemType.AgendaItemDocument,
  SearchResultItemType.Document,
  SearchResultItemType.ContentCloudFile,
  SearchResultItemType.Attachment
]

const buildTitle = (resultItem) => {
  const isMessage = resultItem.type === SearchResultItemType.Message
  if (!isMessage) return resultItem.title

  const discussionName = resultItem.breadcrumbs[resultItem.breadcrumbs.length - 1].name

  return `Message in ${discussionName}`
}

const Title = ({ resultItem }) => {
  const { close: closeSidebar } = useUniversalAi()
  const isMobile = useMobileScreen()

  const { type, sourceDeleted, path } = resultItem
  const title = buildTitle(resultItem)

  const isDocument = DOCUMENT_RESULT_TYPES.includes(type)
  const { extension } = splitFilename(title)

  const handleLinkClick = () => {
    if (sourceDeleted) return
    if (isMobile) closeSidebar()

    window.analytics.track("KnowaQ_Visit-Search-Result", {
      result_item_id: resultItem.id,
      result_item_type: resultItem.type,
      link: resultItem.path
    })

    window.Turbo.visit(path)
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link onClick={handleLinkClick} className={sourceDeleted ? "deleted" : null}>
      {isDocument && <Icon extension={extension} showExtension={false} />}
      <span>{buildTitleWithDeletedLabel(title, sourceDeleted)}</span>
    </Link>
  )
}

Title.propTypes = {
  resultItem: SearchResultItem.isRequired
}

export default Title
