import { func, number, string } from "prop-types"
import React, { useEffect, useRef } from "react"
import { Modal } from "react-bootstrap"

import { handlePSPDFKitInitError } from "src/helpers/pspdfkit"
import { download } from "src/api/apps/externalContent/sources"

import { Link, ModalTitle, PreviewContainer } from "./styles"

const setupPspdfkitPreviewer = async ({ sourceId, container, page, highlights }) => {
  const documentContent = await download(sourceId)

  window.PSPDFKit.load({
    licenseKey: window.PSPDFKitKey,
    disableTextSelection: true,
    document: documentContent,
    toolbarItems: [],
    layers: [],
    enableAutomaticLinkExtraction: false,
    container,
    editableAnnotationTypes: [],
    styleSheets: ["/packs/css/pspdfkit-preview.css"],
    instant: false
  })
    .then((instance) => {
      instance.setViewState((viewState) => {
        const currentPageIndex = page ? Math.max(page - 1, 0) : 0

        return viewState
          .set("allowPrinting", false)
          .set("sidebarPlacement", window.PSPDFKit.SidebarPlacement.END)
          .set("currentPageIndex", currentPageIndex)
      })

      if (highlights) {
        instance
          .search(highlights, {
            startPageIndex: page - 1,
            endPageIndex: page - 1
          })
          .then((results) => {
            instance.setSearchState((searchState) => {
              return searchState.set("results", results)
            })
          })
      }
    })
    .catch(handlePSPDFKitInitError)
}

const SourcePreview = ({
  title,
  onClose,
  sourceId,
  page,
  highlights,
  externalLink,
  externalContentInstanceName
}) => {
  const previewContainerRef = useRef(null)

  useEffect(() => {
    if (!window.PSPDFKit || !previewContainerRef.current || !sourceId) return

    setupPspdfkitPreviewer({
      sourceId,
      page,
      container: previewContainerRef.current,
      highlights
    })

    return () => {
      window.PSPDFKit.unload(previewContainerRef.current)
    }
  }, [sourceId, !!window.PSPDFKit, previewContainerRef.current])

  return (
    <Modal size="xl" show onHide={onClose}>
      <Modal.Header closeButton>
        <ModalTitle>
          <span>{title}</span>

          {externalLink && (
            <Link href={externalLink} target="_blank" rel="noreferrer">
              Learn more on {externalContentInstanceName}
            </Link>
          )}
        </ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <PreviewContainer ref={previewContainerRef} />
      </Modal.Body>
    </Modal>
  )
}

SourcePreview.propTypes = {
  page: number,
  sourceId: number.isRequired,
  title: string.isRequired,
  onClose: func.isRequired,
  highlights: string,
  externalLink: string,
  externalContentInstanceName: string.isRequired
}

export default SourcePreview
