import React from "react"
import { number, func, string } from "prop-types"
import { Container, Row, Col, Button } from "react-bootstrap"
import pluralize from "pluralize"

import { userType } from "../constants"

import RoleSelect from "./RoleSelect"
import {
  ConfirmationPanelContainer,
  SelectedText,
  MembershipContainer,
  ButtonContainer
} from "./styles"

const FooterPanel = ({ user, selectedCount, role, setRole, onBulkAddClick }) => {
  if (!selectedCount) return null

  return (
    <ConfirmationPanelContainer>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <SelectedText>
              {pluralize("meeting pack", selectedCount, true)} selected
            </SelectedText>
            <div>(including all agenda items)</div>
          </Col>
          <Col xs={12} md={6}>
            <MembershipContainer>
              <strong>{user.fullName}</strong> as{" "}
              <RoleSelect role={role} setRole={setRole} />
              <em>Access only</em>
            </MembershipContainer>
          </Col>
          <Col xs={12} md={2}>
            <ButtonContainer>
              <div>
                <Button onClick={onBulkAddClick}>Bulk add</Button>
              </div>
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
    </ConfirmationPanelContainer>
  )
}

FooterPanel.propTypes = {
  user: userType.isRequired,
  selectedCount: number.isRequired,
  role: string.isRequired,
  setRole: func.isRequired,
  onBulkAddClick: func.isRequired
}

export default FooterPanel
