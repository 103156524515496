export const download = async (sourceId) => {
  const response = await fetch(
    `/api/apps/external_content/sources/${sourceId}/download`,
    {
      credentials: "include"
    }
  )

  return response.arrayBuffer()
}
