export const SUGGESTIONS = {
  account: "account",
  app: "app",
  discussion: "discussion",
  folder: "folder",
  group: "group",
  jointScheme: "jointScheme",
  meeting: "meeting",
  privateScheme: "privateScheme",
  search: "search"
}
