import { styled } from "@linaria/react"

export const LoadingStateContainer = styled.div`
  margin-top: 20px;
`

export const LoadingLabel = styled.div`
  color: #3f3f46;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  margin-bottom: 15px;
`

export const Skeleton = styled.div`
  & svg {
    animation: pulse 1.8s linear infinite;
  }

  @keyframes pulse {
    0% {
      fill: #f1f4f9;
    }

    50% {
      fill: #d7dee8;
    }

    100% {
      fill: #f1f4f9;
    }
  }
`
