import React from "react"
import { bool, func } from "prop-types"
import { cx } from "@linaria/core"

import { DropdownIcon, ToggleLabel } from "./styles"

const FiltersToggle = ({ showFilters, toggleShowFilters }) => {
  return (
    <ToggleLabel className={cx(showFilters && "opened")} onClick={toggleShowFilters}>
      <span>{showFilters ? "Hide filters" : "Show filters"}</span>
      <DropdownIcon type="dropdown-arrow" />
    </ToggleLabel>
  )
}

FiltersToggle.propTypes = {
  showFilters: bool.isRequired,
  toggleShowFilters: func.isRequired
}

export default FiltersToggle
