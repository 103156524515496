import { styled } from "@linaria/react"

import { SPACES } from "src/styles/sizes"
import { TEXT_COLORS } from "src/styles/colors"

export const Container = styled.div`
  position: relative;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

export const Caption = styled.div`
  text-align: center;
  color: ${TEXT_COLORS.muted};
  margin-bottom: ${SPACES.small};
`
