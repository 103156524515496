import { stringify, parse } from "qs"

const menuItemsPaths = {
  home: ["meetings", "mentions", "starred_items", "chats"],
  chats: "chats",
  profile: "users/profile",
  contacts: "contacts",
  meetings: "meetings",
  mentions: "mentions",
  starredItems: "starred_items",
  meetingPacks: ["meeting_packs", "agenda_items"],
  meetingPacksView: "view",
  meetingPacksAttendees: "attendees"
}

/**
 * @param {Object} params to add
 * @param {Object} options
 * @param {boolean} options.replace [true] - should query be merged with existing params in URL
 */
export const setParamsToUrl = (params, options = { replace: true }) => {
  const { replace } = options
  const newUrlParams = stringify({
    ...(replace ? {} : parse(window.location.search)),
    ...params
  })

  window.history.pushState({}, "", `?${newUrlParams}`)
}

export const reloadPage = () => {
  if (typeof window !== "undefined" && window.location) {
    window.location.reload()
  }
}

export const refreshWithTurbo = () => {
  if (typeof window !== "undefined" && window.location) {
    window.Turbo.visit(window.location)
  }
}

export const goTo = (path) => window.Turbo.visit(path)

export const isOnThePage = (pageName) => {
  if (!menuItemsPaths[pageName]) {
    return false
  }

  const url = new URL(window.location)

  if (typeof menuItemsPaths[pageName].some === "function") {
    return menuItemsPaths[pageName].some((path) => url.pathname.startsWith(`/${path}`))
  }

  return url.pathname.startsWith(`/${menuItemsPaths[pageName]}`)
}

export const isOnDiscussionPage = (discussionId) => {
  if (!discussionId) {
    return false
  }

  const url = new URL(window.location)

  return url.pathname === `/discussions/${discussionId}`
}

export const getFolderGroupDocumentLink = (folder) => {
  if (!folder) return
  return `
    <a href='/groups/${folder.groupId}/documents?folder_id=${folder.id}' 'data-remote'='true'>
      Go to folder
    </a>
  `
}

export const buildSearchQueryString = (searchObject, prefix) => {
  const queryParams = []
  Object.keys(searchObject).forEach((key) => {
    const param = prefix ? `${prefix}[${key}]` : key
    const value = searchObject[key] || ""
    queryParams.push(
      typeof value === "object"
        ? buildSearchQueryString(value, param)
        : `${encodeURIComponent(param)}=${encodeURIComponent(value)}`
    )
  })
  return queryParams.join("&")
}
