import { func, string } from "prop-types"
import React from "react"

import AnswerContainer from "src/features/UniversalAi/Sidebar/ThreadItem/AnswerContainer"

import { ShortAnswerContainer } from "./styles"

const ShortAnswer = ({ answerText, onClick }) => {
  return (
    <ShortAnswerContainer onClick={onClick}>
      <AnswerContainer answerText={answerText} sourcesVisible={false} />
    </ShortAnswerContainer>
  )
}

ShortAnswer.propTypes = {
  answerText: string.isRequired,
  onClick: func
}

export default ShortAnswer
