import React, { forwardRef } from "react"
import { bool, string } from "prop-types"

import { Container } from "./styles"

const AnswerContainer = forwardRef(({ answerText, sourcesVisible = true }, ref) => {
  return (
    <Container
      ref={ref}
      dangerouslySetInnerHTML={{ __html: answerText }}
      className={sourcesVisible && "sources-visible"}
    />
  )
})

AnswerContainer.propTypes = {
  answerText: string,
  sourcesVisible: bool
}

AnswerContainer.displayName = "AnswerContainer"

export default AnswerContainer
