import { styled } from "@linaria/react"

import { TEXT_COLORS } from "src/styles/colors"
import Icon from "src/styles/components/Icon"

export const DropdownIcon = styled(Icon)``

export const ToggleLabel = styled.label`
  display: inline-block;
  margin: 0;
  text-align: right;
  white-space: nowrap;
  width: auto;
  color: ${TEXT_COLORS.muted};
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.opened ${DropdownIcon} {
    transform: rotate(180deg);
  }
`
