import { useMutation } from "@apollo/client"
import { useCallback } from "react"

import { CHANNELS } from "src/constants/ably"
import { useUniversalAiSelector } from "src/features/UniversalAi/store/"
import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"

import useChannelName from "../useChannelName"

import performSearchMutation from "./performSearchMutation.gql"

const useSearch = ({ focusedItem, onComplete } = {}) => {
  const channelName = useChannelName("search", CHANNELS.search.results)
  const { searchRequestId } = useUniversalAiSelector()

  const [mutate] = useMutation(performSearchMutation)

  const search = (query) => {
    return mutate({
      variables: {
        query,
        channelName,
        searchRequestId,
        where: [{ id: focusedItem.id, type: focusedItem.type }]
      }
    })
  }

  const handleRealtimeUpdates = useCallback(
    (data) => {
      if (data.searchRequestId === searchRequestId) {
        if (onComplete) onComplete(data.results)
      }
    },
    [searchRequestId]
  )

  useRealtimeUpdates(channelName, handleRealtimeUpdates)

  return search
}

export default useSearch
