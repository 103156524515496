import { useEffect, useReducer, useRef, useState } from "react"
import * as Sentry from "@sentry/browser"
import { useDispatch } from "react-redux"

import { DOCUMENT_LOADING_STATES } from "src/features/meetingPacks/MeetingPack/constants"
import MeetingPackStore from "src/features/offline/helpers/MeetingPackStore"
import { setActiveDocumentPage } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"
import { handlePSPDFKitInitError } from "src/helpers/pspdfkit"
import { currentPageIndexKey } from "src/features/meetingPacks/MeetingPack/helpers"

import getBasicConfiguration from "./getBasicConfiguration"
import createModifyEditorView from "./createModifyEditorView"
import createSyncAnnotations from "./createSyncAnnotations"
import { createOfflineHandler } from "./createOfflineHandler"
import setupAnnotationPresetsSaving from "./setupAnnotationPresetsSaving"

// On the iPad there is a chance that PSPDFKit will not be loaded yet.
// This is a workaround to make sure that PSPDFKit is loaded before we try to use it.
const getPSPDFKit = () => {
  return new Promise((resolve) => {
    if (window.PSPDFKit) {
      resolve(window.PSPDFKit)
      return
    }

    const pspdfkitScript = document.createElement("script")
    pspdfkitScript.setAttribute("src", `/pspdfkit/pspdfkit-${window.PSPDFKitVersion}.js`)
    pspdfkitScript.addEventListener("load", () => resolve(window.PSPDFKit), false)
    document.head.appendChild(pspdfkitScript)
  })
}

const setupCurrentPageSaving =
  ({ documentId, dispatch }) =>
  (instance) => {
    instance.addEventListener("viewState.currentPageIndex.change", (pageIndex) => {
      localStorage.setItem(currentPageIndexKey(documentId), pageIndex)
      dispatch(setActiveDocumentPage(pageIndex + 1))
    })
    return instance
  }

const setupCurrentPage =
  ({ documentId }) =>
  (instance) => {
    const page = Number(localStorage.getItem(currentPageIndexKey(documentId)))
    if (page) {
      instance.setViewState((state) => state.set("currentPageIndex", page))
    }
  }

export default ({
  useCache,
  userId,
  documentId,
  page,
  highlighted,
  container,
  customToolbarButtons
}) => {
  const hasCacheInfo = useCache !== undefined
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const lastRenderingDocumentIdRef = useRef(documentId)
  const [loadingState, setLoadingState] = useState(DOCUMENT_LOADING_STATES.notLoaded)
  const [forceUpdateCounter, forceUpdate] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    if (!hasCacheInfo) {
      return
    }

    getPSPDFKit().then((PSPDFKit) => {
      const meetingPackStore = new MeetingPackStore({ useCache })

      lastRenderingDocumentIdRef.current = documentId
      setLoadingState(DOCUMENT_LOADING_STATES.isLoading)

      meetingPackStore
        .getDocumentWithAnnotations({ documentId, userId })
        .then(({ document, annotations }) => {
          PSPDFKit.load({
            ...getBasicConfiguration(),
            container,
            document,
            instantJSON: {
              format: "https://pspdfkit.com/instant-json/v1",
              annotations: (annotations || []).map(({ id, content }) => {
                return {
                  id,
                  ...content
                }
              })
            },
            useIframe: true
          })
            .then(setupCurrentPageSaving({ documentId, dispatch }))
            .then(
              createModifyEditorView({
                highlighted,
                documentId,
                page,
                customToolbarButtons
              })
            )
            .then(createSyncAnnotations({ documentId, dispatch }))
            .then(createOfflineHandler({ customToolbarButtons }))
            .then(setupAnnotationPresetsSaving)
            .then(setupCurrentPage({ documentId }))
            .then(() => setLoadingState(DOCUMENT_LOADING_STATES.loaded))
            .catch((e) => {
              handlePSPDFKitInitError(e)

              // When the user changes documents too ofter it's possible that
              // PSPDFKit.load will be called before the previous PSPDFKit instance is unloaded
              // This causes error "Configuration#container is already used to mount a PSPDFKit for Web instance"
              // As a result - previous document is still shown while the next document is selected by user
              // We won't consider it as a failed state and will just force component rerender
              if (e.message.includes("PSPDFKit.unload(container)")) {
                // to avoid extra reloads when PSPDFKit throws errors from the previous attempts
                if (lastRenderingDocumentIdRef.current === documentId) {
                  forceUpdate()
                }
                return
              }

              setLoadingState(DOCUMENT_LOADING_STATES.loadingFailed)
            })
        })
        .catch((e) => {
          Sentry.captureException(e)
          setLoadingState(DOCUMENT_LOADING_STATES.loadingFailed)
        })
    })
    return () => {
      if (document.querySelector(container)) window?.PSPDFKit?.unload(container)
    }
  }, [documentId, forceUpdateCounter, hasCacheInfo])

  return { loadingState, containerRef }
}
