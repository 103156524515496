import React from "react"
import { bool, func } from "prop-types"
import { Card } from "react-bootstrap"

import Filters from "src/components/MeetingPacks/Filters"
import {
  filterType,
  filterConstraintsType
} from "src/components/MeetingPacks/Filters/constants"

import SelectAllButton from "./SelectAllButton"
import { ActionsContainer, MobileActionsContainer } from "./styles"

const ListActions = ({
  hideSelectAll,
  onSelectAllClick,
  filters,
  setFilters,
  constraints
}) => (
  <>
    <ActionsContainer>
      <div>{!hideSelectAll && <SelectAllButton onClick={onSelectAllClick} />}</div>

      <div>
        <Filters filters={filters} setFilters={setFilters} constraints={constraints} />
      </div>
    </ActionsContainer>

    <MobileActionsContainer>
      <Card>
        <Filters filters={filters} setFilters={setFilters} constraints={constraints} />
      </Card>

      <div>{!hideSelectAll && <SelectAllButton onClick={onSelectAllClick} />}</div>
    </MobileActionsContainer>
  </>
)

ListActions.propTypes = {
  hideSelectAll: bool.isRequired,
  onSelectAllClick: func.isRequired,
  filters: filterType.isRequired,
  setFilters: func.isRequired,
  constraints: filterConstraintsType
}

export default ListActions
