import { shape, string } from "prop-types"
import React from "react"

import { LoadingStateContainer, LoadingLabel, Skeleton } from "./styles"
// eslint-disable-next-line import/no-unresolved
import skeleton from "./skeleton.svg?raw"

const LoadingState = ({ externalContentInstance }) => {
  return (
    <LoadingStateContainer>
      <LoadingLabel>Generating response from {externalContentInstance.name}</LoadingLabel>
      {/* eslint-disable-next-line react/no-danger */}
      <Skeleton dangerouslySetInnerHTML={{ __html: skeleton }} />
    </LoadingStateContainer>
  )
}

LoadingState.propTypes = {
  externalContentInstance: shape({
    name: string.isRequired
  }).isRequired
}

export default LoadingState
