import React from "react"
import { bool, string, number, func } from "prop-types"
import { Modal } from "react-bootstrap"
import pluralize from "pluralize"
import indefinite from "indefinite"

import { Title, Footer } from "src/styles/components/Modal"

import { ROLES, userType } from "../constants"

const ConfirmationModal = ({ show, user, role, selectedCount, onConfirm, onCancel }) => {
  const roleName = ROLES[role]
  const roleArticle = indefinite(roleName, { articleOnly: true, caseInsensitive: true })
  const meetingPacksText = pluralize("past Meeting Pack", selectedCount, true)

  return (
    <Modal show={show}>
      <Modal.Header>
        <Title>
          Add {user.fullName} to {meetingPacksText}
        </Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are about to add <strong>{user.fullName}</strong> as {roleArticle}{" "}
          <strong>{roleName}</strong> (<em>Access Only</em>) to {meetingPacksText}.
        </p>
        <p>Access will be granted immediately.</p>
        <p>
          <strong>
            N.B. You cannot quickly undo this process. Are you sure you wish to proceed?
          </strong>
        </p>
      </Modal.Body>
      <Footer>
        <button className="button is-secondary" onClick={onCancel}>
          No, cancel
        </button>
        <button className="button" onClick={onConfirm}>
          Yes, proceed
        </button>
      </Footer>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  show: bool.isRequired,
  user: userType.isRequired,
  selectedCount: number.isRequired,
  role: string.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired
}

export default ConfirmationModal
