import React from "react"
import { number, shape, string } from "prop-types"

import {
  Header,
  Column,
  UserColumn,
  Table,
  FilterInput,
  useSorting,
  useFiltering
} from "src/components/AttandeesTable"
import useLayoutHeader from "src/hooks/useLayoutHeader"

import useAccountMembershipsQuery from "../useAccountMembershipsQuery"

import ActionsColumn from "./ActionsColumn"
import RoleColumn from "./RoleColumn"

const columns = [
  {
    key: "fullName",
    label: "FULL NAME"
  },
  {
    key: "email",
    label: "EMAIL"
  },
  {
    key: "jobTitle",
    label: "ROLE"
  },
  {
    key: "role",
    label: "ORG ROLE"
  },
  {
    key: "twoFactorAuthEnabled",
    label: "2FA"
  },
  {
    key: "lastSeenStatus",
    label: "ACTIVE IN ORG"
  }
]

const AttendeesTable = ({ account }) => {
  useLayoutHeader()

  const { accountMemberships } = useAccountMembershipsQuery({ accountId: account.id })

  const rawUsers = (accountMemberships || []).map((membership) => ({
    ...membership,
    ...membership.user,
    jobTitle: membership.userJobTitle,
    lastSeenStatus: membership.lastSeenStatus,
    lastActiveAt: membership.lastActiveAt
  }))

  const {
    sortedItems: sortedUsers,
    sort,
    sortDirection,
    sortBy
  } = useSorting({
    items: rawUsers,
    sortingColumnsRewrite: {
      lastSeenStatus: (item) => new Date(item.lastActiveAt).getTime()
    }
  })
  const { filteredItems: users, setFilter } = useFiltering({
    items: sortedUsers,
    filteredFields: ["email", "fullName", "jobTitle"]
  })

  return (
    <div className="fluid-container my-3">
      <div className="container user-management-container">
        <div className="card">
          <FilterInput setFilter={setFilter} />
          <div className="table-responsive-xl">
            <Table className="table">
              <Header
                columns={columns}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sort}
              />
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.id}
                    data-id={user.id}
                    data-behavior="user-management-table-row"
                  >
                    <UserColumn user={user} />
                    <Column>{user.email}</Column>
                    <Column>{user.jobTitle}</Column>
                    <RoleColumn role={user.role} />
                    <Column>{user.twoFactorAuthEnabled ? "Enabled" : null}</Column>
                    <Column>{user.lastSeenStatus}</Column>
                    <ActionsColumn user={user} account={account} />
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

AttendeesTable.propTypes = {
  account: shape({ id: number.isRequired, name: string.isRequired })
}

export default AttendeesTable
