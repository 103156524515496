import React, { useState } from "react"
import classNames from "classnames"
import { Card } from "react-bootstrap"

import FiltersToggle from "src/components/FiltersToggle"
import Filters from "src/components/MeetingPacks/Filters"
import useMediaQuery from "src/hooks/useMediaQuery"
import useToggle from "src/hooks/useToggle"
import { getTagId } from "src/resources/tags/helpers"
import { getAccountOptionId } from "src/resources/accounts/helpers"
import { getYear } from "src/resources/years/helpers"
import { DEFAULT_FILTERS } from "src/resources/meetingPacks/constants"
import { BREAKPOINTS } from "src/styles/sizes"
import { ContentContainer } from "src/styles/components/ContentComponents"
import { configureStoredFilters } from "src/helpers/filters"

import MeetingsList from "./MeetingsList"

const FILTERS_STORAGE_KEY = "meetingPacksFilters"

const Meetings = () => {
  const isMobileScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)

  const { storedFilters, storeFilters } = configureStoredFilters(
    FILTERS_STORAGE_KEY,
    DEFAULT_FILTERS
  )

  const [filters, setFilters] = useState(storedFilters)
  const [showFilters, toggleShowFilters] = useToggle("FiltersToggle", true)

  const updateFilters = (newFilters) => {
    storeFilters(newFilters)
    setFilters(newFilters)
  }

  return (
    <ContentContainer>
      <div className="list-heading align-items-baseline">
        <h4 className="hidden-on-large-screens">My Meetings</h4>
        {isMobileScreen ? (
          <FiltersToggle
            showFilters={showFilters}
            toggleShowFilters={toggleShowFilters}
          />
        ) : (
          <div className="list-heading-actions justify-content-end">
            <Filters filters={filters} setFilters={updateFilters} />
          </div>
        )}
      </div>
      {isMobileScreen && (
        <Card className={classNames({ "d-none": !showFilters })}>
          <Filters filters={filters} setFilters={updateFilters} />
        </Card>
      )}
      <MeetingsList
        accountId={getAccountOptionId(filters.accountFilter)}
        tagId={getTagId(filters.tagFilter)}
        year={getYear(filters.yearFilter)}
      />
    </ContentContainer>
  )
}

export default Meetings
