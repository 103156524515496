import { useEffect, useState } from "react"

import useMobileScreen from "src/hooks/useMobileScreen"
import { SourceClickTarget } from "src/features/UniversalAi/constants"

import { SOURCE_ELEMENT_SELECTOR } from "./constants"

const initialState = { source: null, htmlElement: null }

const useSourceInteractions = ({
  sources,
  sourcePreviewRef,
  containerRef,
  onSourceNavigate,
  skip = false
}) => {
  const [interaction, setInteraction] = useState(initialState)
  const isPreviewVisible = !!interaction.source
  const isMobile = useMobileScreen()
  const isDesktop = !isMobile

  const findSourceByIndex = (index) => sources[index - 1]

  useEffect(() => {
    const container = containerRef.current
    if (!container || skip) return

    const handleMouseOver = (e) => {
      const element = e.target

      const index = parseInt(element.innerText) || -1
      const source = findSourceByIndex(index)
      if (!source) return

      setInteraction({ source, htmlElement: element })
    }

    const handleMouseLeave = () => {
      if (isMobile) return

      setInteraction(initialState)
    }

    const handleClick = (e) => {
      e.stopPropagation()
      const element = e.target

      const index = parseInt(element.innerText) || -1
      const source = findSourceByIndex(index)
      if (!source) return

      if (isDesktop && !source.sourceDeleted) {
        onSourceNavigate(source, SourceClickTarget.Answer)
      }

      if (isMobile) {
        setInteraction({ source, htmlElement: element })
      }
    }

    const bindListeners = (element) => {
      element.addEventListener("mouseover", handleMouseOver)
      element.addEventListener("mouseleave", handleMouseLeave)
      element.addEventListener("click", handleClick)
    }

    const removeListeners = (element) => {
      element.removeEventListener("mouseover", handleMouseOver)
      element.removeEventListener("mouseleave", handleMouseLeave)
      element.removeEventListener("click", handleClick)
    }

    const sourcesElements = container.querySelectorAll(SOURCE_ELEMENT_SELECTOR)
    sourcesElements.forEach((element) => bindListeners(element))

    return () => {
      sourcesElements.forEach((element) => removeListeners(element))
    }
  }, [!!containerRef.current, skip, isMobile])

  // Close source preview when user clicks outside of source preview
  useEffect(() => {
    const handleGlobalClick = (e) => {
      if (!sourcePreviewRef.current) return
      if (!isPreviewVisible) return
      if (sourcePreviewRef.current.contains(e.target)) return

      setInteraction(initialState)
    }

    document.addEventListener("click", handleGlobalClick)

    return () => document.removeEventListener("click", handleGlobalClick)
  }, [!!sourcePreviewRef.current, isPreviewVisible])

  return interaction
}

export default useSourceInteractions
