import { getFolderGroupDocumentLink } from "src/helpers/url"
import { showCaughtErrorMessage } from "src/helpers/errors"
import { showFlashMessage } from "src/helpers/flash"

import box from "./api"

export const fetchSchemeFolders = (data) => () => {
  return box
    .subFolders(data)
    .then((response) => response.data)
    .catch((error) => showCaughtErrorMessage(error))
}

export const handleSuccess = ({ targetFolder, currentGroup, message }) => {
  const isCurrentGroup = currentGroup.id === targetFolder.groupId

  if (isCurrentGroup) {
    showFlashMessage("info", message)
  } else {
    showFlashMessage(
      "success",
      [message, getFolderGroupDocumentLink(targetFolder)].join(" "),
      { timeout: 20000 }
    )
  }
}

export const moveBoxItems =
  (requestData = {}) =>
  () => {
    showFlashMessage("warning", "We are moving selected items. Please wait.", {
      timeout: false
    })

    return box.move(requestData).catch((error) => showCaughtErrorMessage(error))
  }

export const copyBoxItems =
  (requestData = {}) =>
  () => {
    showFlashMessage("warning", "We are copying selected items. Please wait.", {
      timeout: false
    })

    return box.copy(requestData).catch((error) => showCaughtErrorMessage(error))
  }
