import { useCallback, useEffect } from "react"

import { getCurrentUserId } from "src/helpers/user"

// useAblyChannel is deprecated, use useRealtimeUpdates instead
const useAblyChannel = (channelName, { onMessage, filterByUsersId = true }) => {
  const handleMessage = useCallback(
    (message) => {
      const { userIds } = message.data
      if (filterByUsersId && userIds && !userIds.includes(getCurrentUserId())) return

      onMessage(message)
    },
    [onMessage]
  )

  useEffect(() => {
    if (!channelName) return
    const channel = window.AblyRealtimeClient?.channels?.get(channelName)

    if (channel) channel.subscribe(handleMessage)

    return () => {
      if (channel) channel.unsubscribe(handleMessage)
    }
  }, [channelName, handleMessage])
}

export default useAblyChannel
