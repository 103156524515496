export const manageAttendees = (accountId) => `/accounts/${accountId}/manage`
export const bulkAddUserToMeetingPacks = (accountId, userId) =>
  `${manageAttendees(accountId)}/meeting_packs/bulk_add/${userId}`

export const manageTeams = (accountId) => `/accounts/${accountId}/teams`

export const manageApps = (accountId) => `/accounts/${accountId}/apps`
export const newApp = (accountId) => `${manageApps(accountId)}/new`
export const showApp = (accountId, appId) => `${manageApps(accountId)}/${appId}`
export const editApp = (accountId, appId) => `${showApp(accountId, appId)}/edit`
export const editPage = (accountId, appId, pageId) =>
  `${showApp(accountId, appId)}/pages/${pageId}`
