import React from "react"
import { arrayOf, func } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { optionType } from "src/constants/propTypes"

const AccountFilter = ({ selectedOption, setSelectedOption, options }) => {
  if (options.length <= 1) return null

  return (
    <SearchableSelect
      prefix="In"
      options={[DEFAULT_ACCOUNT_FILTER, ...options]}
      selected={selectedOption}
      setSelected={setSelectedOption}
      data-testid="account-filter"
    />
  )
}

AccountFilter.propTypes = {
  selectedOption: optionType,
  setSelectedOption: func.isRequired,
  options: arrayOf(optionType).isRequired
}

export default AccountFilter
