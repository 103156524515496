import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from "react"
import { number, string } from "prop-types"
import { useMutation } from "@apollo/client"
import { styled } from "@linaria/react"
import { useSelector } from "react-redux"
import classnames from "classnames"

import ContentLoader from "src/styles/components/ContentLoader"
import { useUniversalAiSelector } from "src/features/UniversalAi/store"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import { scrollDown } from "src/helpers/document"
import { linkActiveColor } from "src/styles/colors"
import { TRANSCRIPT_SCROLL_EVENT } from "src/features/meetingPacks/MeetingPack/Transcript/View/constants"
import { getAgendaItems } from "src/features/meetingPacks/MeetingPack/selectors"
import useStreaming from "src/hooks/ai/useStreaming"
import useCurrentThreadItem from "src/features/UniversalAi/Sidebar/useCurrentThreadItem"
import useChannelName from "src/features/UniversalAi/Sidebar/useChannelName"
import { Status } from "src/features/UniversalAi/constants"
import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"

import { Base, Content, Container, Footer } from "../Base"
import scrollPositionHandler from "../scrollPositionHandler"

import generateAiMinutesMutation from "./generateAiMinutes.gql"
import OutdatedWarning from "./OutdatedWarning"

const GenerateDraftMinutes = ({ objectId, objectType, query, className }) => {
  const { requestId } = useUniversalAiSelector()
  const agendaItem = useSelector(getAgendaItems).find((item) => item.id === objectId)
  const contentRef = useRef()
  const channelName = useChannelName("draftMinutes", CHANNELS.aiAnswer.draftMinutes)

  const {
    currentThreadItem,
    isLoading,
    isInProgress,
    isFinished,
    setCurrentThreadItem,
    updateCurrentThreadItem
  } = useCurrentThreadItem()

  const { setContent, setFinished, reset } = useStreaming({
    streamId: "draftMinutes",
    onChange: (answer) => {
      updateCurrentThreadItem({ answer, status: Status.InProgress })
      scrollDown(() => contentRef.current)
    },
    onComplete: (answer) => {
      updateCurrentThreadItem({ answer, status: Status.Finished })
    }
  })

  const [draftMinutesOutdated, setDraftMinutesOutdated] = useState(false)
  const [regenerating, setRegenerating] = useState(false)
  const [generateAiMinutes] = useMutation(generateAiMinutesMutation)

  const commandResultExists = !!currentThreadItem?.answer

  useEffect(() => {
    if (commandResultExists || !requestId || !channelName) return

    reset()

    setCurrentThreadItem({
      query,
      focusable: { id: objectId, type: objectType },
      status: Status.Loading
    })

    generateAiMinutes({
      variables: {
        agendaItemId: objectId,
        aiRequestId: requestId,
        query,
        channelName
      }
    })
  }, [
    objectId,
    objectType,
    query,
    commandResultExists,
    requestId,
    channelName,
    regenerating
  ])

  const regenerateDraftMinutes = () => {
    reset()
    setRegenerating(true)
    setDraftMinutesOutdated(false)

    updateCurrentThreadItem({
      query,
      focusable: { id: objectId, type: objectType },
      status: Status.Loading
    })

    generateAiMinutes({
      variables: {
        agendaItemId: objectId,
        aiRequestId: requestId,
        query,
        channelName,
        withCache: false
      }
    })
  }

  const handleDraftMinutesUpdates = useCallback(
    (data) => {
      if (
        data.agendaItemId?.toString() === objectId?.toString() &&
        data.aiRequestId === requestId
      ) {
        const result = data.minutes || data.error

        setContent(result, { stream: !data.withCache })
        setDraftMinutesOutdated(!!data.outdated)
        if (result) setRegenerating(false)
        if (data.finished) setFinished(true)
      }
    },
    [objectId, requestId]
  )

  useRealtimeUpdates(channelName, handleDraftMinutesUpdates)

  useAblyChannel(CHANNELS.meetingPacksTranscript, {
    onMessage: ({ data, name }) => {
      if (name === "outdated" && data.agenda_item_ids.includes(objectId)) {
        setDraftMinutesOutdated(true)
      }
    }
  })

  useLayoutEffect(
    () =>
      scrollPositionHandler({
        element: contentRef.current,
        key: `generateDraftMinutes/${objectType}/${objectId}`,
        restorePosition: isFinished
      }),
    [!!contentRef.current, isFinished, objectType, objectId]
  )

  const draftMinutes = currentThreadItem?.answer

  const scrollTo = (event) => {
    if (!event.target.dataset.source) return

    const source = event.target.dataset.source.split(", ")
    const scrollEvent = new CustomEvent(TRANSCRIPT_SCROLL_EVENT, { detail: { source } })
    window.dispatchEvent(scrollEvent)
  }

  return (
    <Base title={`Draft Minutes for <h6>${agendaItem.name}</h6>`} titleVersion="semiBold">
      <Container>
        <Content
          ref={contentRef}
          className={classnames(className, { draftMinutesOutdated })}
        >
          {isLoading && <ContentLoader />}
          {draftMinutesOutdated && <OutdatedWarning onClick={regenerateDraftMinutes} />}
          {/* eslint-disable react/no-danger */}
          {draftMinutes && !isLoading && (
            <div
              className="minutes-content"
              onClick={scrollTo}
              aria-hidden="true"
              dangerouslySetInnerHTML={{
                __html: draftMinutes
              }}
            />
          )}
          {/* eslint-enable react/no-danger */}
        </Content>
      </Container>
      <Footer
        loading={isLoading || isInProgress}
        content={draftMinutes}
        contentRef={contentRef}
        buttonTitle="Copy Minutes"
      />
    </Base>
  )
}

GenerateDraftMinutes.propTypes = {
  objectId: number.isRequired,
  objectType: string.isRequired,
  query: string.isRequired
}

export default styled(GenerateDraftMinutes)`
  position: relative;
  padding-left: 16px;
  padding-right: 16px;

  &.draftMinutesOutdated {
    margin-top: 40px;
  }

  ${OutdatedWarning} {
    position: absolute;
    top: -40px;
  }

  .icon-quote {
    font-size: 1rem;
    color: ${linkActiveColor};
    margin-left: 5px;
    cursor: pointer;
  }
`
