import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import { styled } from "@linaria/react"
import { number } from "prop-types"

import { showFlashMessage } from "src/helpers/flash"
import * as routes from "src/features/ManageAccount/routes"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import Title from "src/features/ManageAccount/Shared/Title"
import Form from "src/features/ManageAccount/AirtableApps/Form"
import useLayoutHeader from "src/hooks/useLayoutHeader"

import appAirtableInstanceQuery from "./appAirtableInstance.gql"
import deleteAppMutation from "./deleteAppMutation.gql"

const EditApp = ({ className, accountId }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState({})

  const { data: instanceData } = useQuery(appAirtableInstanceQuery, {
    variables: { id }
  })
  useLayoutHeader({ useDefault: false, title: instanceData?.appAirtableInstance?.name })

  useEffect(() => {
    if (!instanceData?.appAirtableInstance) return

    const page = instanceData.appAirtableInstance.pages[0]

    setInitialValues({
      ...instanceData.appAirtableInstance,
      pageId: page.id,
      baseId: page.baseId,
      tableId: page.tableId
    })
  }, [instanceData])

  const [deleteApp, { deleteAppMutationLoading }] = useMutation(deleteAppMutation, {
    refetchQueries: ["appAirtableInstances"]
  })

  const handleDeleteApp = () =>
    deleteApp({ variables: { id } }).then(({ data = {} }) => {
      if (data.deleteAirtableInstance?.successful) {
        navigate(routes.manageApps(accountId))
        showFlashMessage("success", "App was deleted.")
      }
    })

  const { modal: deleteAppModal, open: openDeleteAppModal } = useConfirmationModal({
    title: "Confirmation",
    message: "Are you sure you want to delete this app?",
    onConfirm: handleDeleteApp
  })

  return (
    <>
      {deleteAppModal}
      <div className={className}>
        <Title text="Edit Application" />

        <Form
          id={id}
          accountId={accountId}
          initialValues={initialValues}
          backRoute={routes.showApp(accountId, id)}
        />

        {id && (
          <button
            className="link delete-bottom-link"
            onClick={openDeleteAppModal}
            disabled={deleteAppMutationLoading}
          >
            <div className="icon-cancel-circled mrx" />
            <span>Delete Application</span>
          </button>
        )}
      </div>
    </>
  )
}

EditApp.propTypes = {
  accountId: number.isRequired
}

export default styled(EditApp)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .container {
    height: 100%;
  }

  .delete-bottom-link {
    background: none;
    border: none;
    margin-top: auto;
    flex-grow: 1;
  }
`
