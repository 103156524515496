import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store"

const useExpandedExternalContentAnswer = (threadItem) => {
  const { expandedExternalContentAnswers } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const expanded = !!expandedExternalContentAnswers[threadItem.id]

  const expand = () => {
    dispatch(actions.expandExternalContentAnswer(threadItem.id))
  }

  const collapse = () => {
    dispatch(actions.collapseExternalContentAnswer(threadItem.id))
  }

  return { expanded, expand, collapse }
}

export default useExpandedExternalContentAnswer
