import React from "react"
import { arrayOf, func } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import { DEFAULT_YEAR_FILTER } from "src/resources/years/constants"
import { optionType } from "src/constants/propTypes"

const YearFilter = ({ selectedOption, setSelectedOption, options }) => {
  return (
    <SearchableSelect
      prefix="Year"
      options={[DEFAULT_YEAR_FILTER, ...options]}
      selected={selectedOption}
      setSelected={setSelectedOption}
      data-testid="year-filter"
    />
  )
}

YearFilter.propTypes = {
  selectedOption: optionType,
  setSelectedOption: func.isRequired,
  options: arrayOf(optionType).isRequired
}

export default YearFilter
