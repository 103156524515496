import { generateUniqId } from "src/helpers/string"

const initialState = {
  mode: null, // "sidebar", "modal"
  command: null, // "Summarize as text", "Summarize as bullets"
  focusedItem: null, // { id: "string", type: "string", title: "string" }
  query: "", // Current value of "query" input
  currentThreadItem: null, // { id: "string", query: "string" , answer: "string" }
  expandedThreadItems: {}, // { 42: true }
  openedSources: {}, // { 42: true }
  openedSearchResults: {}, // { 42: true }
  scrollPosition: 0,
  scrollDirection: null, // "up" or "down"
  channels: {},
  streaming: {},
  searchRequestId: generateUniqId(),
  requestId: generateUniqId(),
  expandedExternalContentAnswers: {}, // { 42: true }
  expandedExternalContentAnswerSources: {} // { 42: true }
}

export default initialState
