import { useEffect } from "react"

import { getCurrentUserId } from "src/helpers/user"

const useRealtimeUpdates = (action, handleData) => {
  useEffect(() => {
    const currentUserId = getCurrentUserId()
    if (!currentUserId || !action) return

    const handleMessage = (message) => {
      if (message.name !== action) return
      const { data } = message

      handleData(data)
    }

    const channel = window.RealtimeUpdatesClient?.channels?.get(`user:${currentUserId}`)

    if (channel) channel.subscribe(handleMessage)

    return () => {
      if (channel) channel.unsubscribe(handleMessage)
    }
  }, [action, handleData])
}

export default useRealtimeUpdates
