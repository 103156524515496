import React from "react"
import { func } from "prop-types"

import { SelectAllButton as StyledButton } from "./styles"

const SelectAllButton = ({ onClick }) => (
  <StyledButton onClick={onClick}>Select all</StyledButton>
)

SelectAllButton.propTypes = {
  onClick: func.isRequired
}

export default SelectAllButton
