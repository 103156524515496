import React from "react"
import { number } from "prop-types"

import * as routes from "../routes"

import MenuItem from "./MenuItem"

const MainMenu = ({ accountId }) => {
  if (!accountId) return null

  const manageAttendeesPath = routes.manageAttendees(accountId)
  const manageTeamsPath = routes.manageTeams(accountId)
  const manageAppsPath = routes.manageApps(accountId)
  const currentPath = window.location.pathname

  return (
    <>
      <MenuItem
        caption="People"
        highlighted={currentPath.startsWith(manageAttendeesPath)}
        path={manageAttendeesPath}
        iconClassName="icon-user"
      />

      <MenuItem
        caption="Teams"
        highlighted={currentPath.startsWith(manageTeamsPath)}
        path={manageTeamsPath}
        iconClassName="icon-user-supervisor"
      />

      {currentPath.startsWith(manageAppsPath) && (
        <MenuItem
          caption="Applications"
          highlighted={currentPath.startsWith(manageAppsPath)}
          path={manageAppsPath}
          iconClassName="icon-arrow-right-squared"
        />
      )}
    </>
  )
}

MainMenu.propTypes = {
  accountId: number
}

export default MainMenu
