import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import { BASE_TEXT_COLOR } from "src/features/UniversalAi/Sidebar/styles"
import Icon from "src/styles/components/Icon"

export const CopyResponseContainer = styled.div`
  margin-top: 16px;
`

export const CopyResponseButton = styled(UnstyledButton)`
  background-color: #f4f4f5;
  font-weight: 500;
  color: ${BASE_TEXT_COLOR};
  padding: 5px 12px 5px 12px;
  border-radius: 200px;

  &:hover {
    background-color: #f4f4f5;
  }
`

export const CopyIcon = styled(Icon)`
  margin-right: 5px;
`
