import React from "react"
import { arrayOf, func } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import SchemeOption from "src/components/SchemeSelect/Option"
import { DEFAULT_TAG_FILTER } from "src/resources/tags/constants"
import { optionType } from "src/constants/propTypes"

const TagFilter = ({ selectedOption, setSelectedOption, options }) => {
  return (
    <SearchableSelect
      components={{ Option: SchemeOption }}
      prefix="Tag"
      options={[DEFAULT_TAG_FILTER, ...options]}
      selected={selectedOption}
      setSelected={setSelectedOption}
      data-testid="tag-filter"
    />
  )
}

TagFilter.propTypes = {
  selectedOption: optionType,
  setSelectedOption: func.isRequired,
  options: arrayOf(optionType).isRequired
}

export default TagFilter
