import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"

export const PreviewContainer = styled.div`
  height: 80vh;
`

export const ModalTitle = styled(Modal.Title)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const Link = styled.a`
  font-size: 18px;
`
