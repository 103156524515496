import { useMutation } from "@apollo/client"

import bulkAddUserToMeetingPacksMutation from "./bulkAddUserToMeetingPacksMutation.gql"

const useBulkAddUserToMeetingPacks = ({
  accountId,
  meetingPackIds,
  userId,
  role,
  requestId
}) => {
  const [bulkAddUserToMeetingPacks] = useMutation(bulkAddUserToMeetingPacksMutation, {
    variables: { accountId, meetingPackIds, userId, role, requestId }
  })

  return { bulkAddUserToMeetingPacks }
}

export default useBulkAddUserToMeetingPacks
