import { useQuery } from "@apollo/client"

import accountMembershipsQuery from "./accountMembershipsQuery.gql"

const useAccountMembershipQuery = ({ accountId, userId, skip }) => {
  const { data, loading } = useQuery(accountMembershipsQuery, {
    variables: { accountId, userId },
    skip
  })

  return { accountMemberships: data?.accountMemberships, loading }
}

export default useAccountMembershipQuery
