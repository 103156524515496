import React from "react"
import { arrayOf, bool, number, func } from "prop-types"

import ContentLoader from "src/styles/components/ContentLoader"
import EmptyContent from "src/styles/components/EmptyContent"

import MeetingPack from "./MeetingPack"
import { meetingPackType } from "./constants"

const MeetingPacks = ({ meetingPacks, loading, selectedIds, onMeetingPackClick }) => {
  if (loading) return <ContentLoader />

  return (
    <>
      {!meetingPacks.length && (
        <EmptyContent>There are no meetings that match your filters.</EmptyContent>
      )}

      {meetingPacks.map((meetingPack) => (
        <MeetingPack
          key={meetingPack.id}
          meetingPack={meetingPack}
          selected={selectedIds.includes(meetingPack.id)}
          onClick={() => onMeetingPackClick(meetingPack.id)}
        />
      ))}
    </>
  )
}

MeetingPacks.propTypes = {
  meetingPacks: arrayOf(meetingPackType),
  loading: bool.isRequired,
  selectedIds: arrayOf(number).isRequired,
  onMeetingPackClick: func.isRequired
}

export default MeetingPacks
