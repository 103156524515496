import { be } from "src/helpers/document"

/**
 * Shows and hides flash message
 * @param level {string} info|success|warning|danger
 * @param message {string}
 * @param container {jQuery|HTMLElement}
 * @return {jQuery|HTMLElement}
 */
const generateFlashMessage = (level, message, container) => {
  const flashContainer = container?.length ? container : be("flash-container")
  const alertElement = $(`
    <div 'data-behavior'='flash-message' class='alert is-${level}'>
      ${message}
      <button type='button' class='close' 'data-dismiss'='alert' 'aria-label'='Close'>
        <span 'aria-hidden'='true'>&times;</span>
      </button>
    </div>
  `)

  flashContainer.html(alertElement)
  alertElement.find("button.close").click(() => alertElement.remove())
  return alertElement
}

const delayedRemove = (element, timeout) => {
  if (element.length) {
    return setTimeout(() => element.remove(), timeout)
  }
}

const defaultTimeout = 4000

const showFlashMessage = (
  level,
  message,
  { container, timeout = defaultTimeout } = {}
) => {
  const alertElement = generateFlashMessage(level, message, container)
  if (timeout) delayedRemove(alertElement, timeout)
}

export { showFlashMessage }
