import { shape, number, string, arrayOf } from "prop-types"

export const ROLES = {
  admin: "Admin",
  member: "Member",
  guest: "Guest"
}

export const tagType = shape({
  name: string.isRequired,
  colorId: number.isRequired
})

export const meetingPackType = shape({
  id: number.isRequired,
  name: string.isRequired,
  publicationBadge: string.isRequired,
  startDate: string.isRequired,
  timeZone: string.isRequired,
  tags: arrayOf(tagType).isRequired
})

export const userType = shape({
  id: number.isRequired,
  fullName: string.isRequired
})
