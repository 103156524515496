import React from "react"
import { bool, string } from "prop-types"

import { ExpandableContentChildren, ExpandableContentContainer } from "./styles"

const ExpandableContent = ({ expanded, transitionDuration = "1s", children }) => {
  return (
    <ExpandableContentContainer
      className={expanded ? "expanded" : null}
      transitionDuration={transitionDuration}
    >
      <ExpandableContentChildren transitionDuration={transitionDuration}>
        {children}
      </ExpandableContentChildren>
    </ExpandableContentContainer>
  )
}

ExpandableContent.propTypes = {
  expanded: bool.isRequired,
  transitionDuration: string
}

export default ExpandableContent
