import { Modes, Commands, Status } from "../constants"

const isProcessing = (currentThreadItem) => {
  return (
    currentThreadItem?.status === Status.Loading ||
    currentThreadItem?.status === Status.InProgress
  )
}

export const setFocusedItem = (state, action) => {
  const { id, type, title } = action.payload

  state.focusedItem = { id: id?.toString(), type, title }
}

export const summariseAsTextInSidebar = (state, action) => {
  if (isProcessing(state.currentThreadItem)) return

  setFocusedItem(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.SummariseAsText
  state.currentThreadItem = null
}

export const queryInSidebar = (state, action) => {
  if (isProcessing(state.currentThreadItem)) return

  setFocusedItem(state, action)

  if (
    [Commands.GenerateExtensiveMinutes, Commands.GenerateShortMinutes].includes(
      state.command
    )
  )
    state.currentThreadItem = null

  state.mode = Modes.Sidebar
  state.command = Commands.Query
}

export const generateExtensiveMinutesInSidebar = (state, action) => {
  if (isProcessing(state.currentThreadItem)) return

  setFocusedItem(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.GenerateExtensiveMinutes
  state.currentThreadItem = null
}

export const generateShortMinutesInSidebar = (state, action) => {
  if (isProcessing(state.currentThreadItem)) return

  setFocusedItem(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.GenerateShortMinutes
  state.currentThreadItem = null
}

export const setQuery = (state, action) => {
  state.query = action.payload
}

export const setCurrentThreadItem = (state, action) => {
  state.currentThreadItem = action.payload
}

export const updateCurrentThreadItem = (state, action) => {
  state.currentThreadItem = { ...state.currentThreadItem, ...action.payload }
}

export const updateExternalContentAnswer = (state, action) => {
  state.currentThreadItem = {
    ...state.currentThreadItem,
    externalContentAnswer: {
      ...(state.currentThreadItem?.externalContentAnswer || {}),
      ...action.payload
    }
  }
}

export const setCommand = (state, action) => {
  state.command = action.payload
}

export const expandThreadItem = (state, action) => {
  state.expandedThreadItems[action.payload] = true
}

export const collapseThreadItem = (state, action) => {
  state.expandedThreadItems[action.payload] = false
}

export const openSources = (state, action) => {
  state.openedSources[action.payload] = true
}

export const closeSources = (state, action) => {
  state.openedSources[action.payload] = false
}

export const openSearchResults = (state, action) => {
  state.openedSearchResults[action.payload] = true
}

export const closeSearchResults = (state, action) => {
  state.openedSearchResults[action.payload] = false
}

export const saveScrollPosition = (state, action) => {
  const newPosition = action.payload
  const currentPosition = state.scrollPosition

  if (newPosition > currentPosition) {
    state.scrollDirection = "down"
  } else if (newPosition < currentPosition) {
    state.scrollDirection = "up"
  }

  state.scrollPosition = newPosition
}
// Preserving all fields except "mode" (to close sidebar) as it gives better UX:
// user opens sidebar and sees previous state of the sidebar
export const close = (state) => ({ ...state, mode: null })

export const saveChannel = (state, action) => {
  const { channelType, channelName } = action.payload

  state.channels = { ...state.channels, [channelType]: channelName }
}

export const updateStreaming = (state, action) => {
  const { streamId, ...rest } = action.payload
  state.streaming[streamId] = { ...state.streaming[streamId], ...rest }
}

export const expandExternalContentAnswer = (state, action) => {
  state.expandedExternalContentAnswers[action.payload] = true
}

export const collapseExternalContentAnswer = (state, action) => {
  state.expandedExternalContentAnswers[action.payload] = false
}

export const expandExternalContentAnswerSources = (state, action) => {
  state.expandedExternalContentAnswerSources[action.payload] = true
}

export const collapseExternalContentAnswerSources = (state, action) => {
  state.expandedExternalContentAnswerSources[action.payload] = false
}
