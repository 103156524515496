import React, { useCallback, useEffect, useMemo } from "react"
import { useApolloClient } from "@apollo/client"
import { sample } from "lodash"

import { CHANNELS } from "src/constants/ably"
import { FROALA_AI_ANSWER_READY, FROALA_AI_BUTTON_CLICKED } from "src/constants/events"
import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"

import generateAiTextMutation from "./generateAiTextMutation.gql"

const FroalaAiButton = () => {
  const apolloClient = useApolloClient()
  const channelName = useMemo(() => sample(CHANNELS.aiAnswer.froala), [])

  const postMessage = ({ answer, success, error }) =>
    window.dispatchEvent(
      new CustomEvent(FROALA_AI_ANSWER_READY, {
        detail: { answer, success, error }
      })
    )

  const handleRealtimeUpdates = useCallback((data) => {
    postMessage({
      answer: data.answer?.replace(/^\n+/, ""),
      success: data.success,
      error: data.error
    })
  }, [])

  useRealtimeUpdates(channelName, handleRealtimeUpdates)

  const handleFroalaAiButtonClick = (event) => {
    apolloClient
      .mutate({
        mutation: generateAiTextMutation,
        variables: {
          instruction: event.detail.instruction,
          message: event.detail.message,
          analyticsContext: event.detail.analyticsContext,
          channelName
        }
      })
      .then(({ data }) => {
        if (!data?.generateAiFroalaText?.success) postMessage({ success: false })
      })
      .catch(() => postMessage({ success: false }))
  }

  useEffect(() => {
    window.addEventListener(FROALA_AI_BUTTON_CLICKED, handleFroalaAiButtonClick)

    return () =>
      window.removeEventListener(FROALA_AI_BUTTON_CLICKED, handleFroalaAiButtonClick)
  }, [])

  return <div />
}

export default FroalaAiButton
